import React, { useState } from 'react'
import { Fragment } from 'react';

export default function DropdownElement({top, bottom}) {
    const [open, setOpen] = useState(false);
    
    const topStyles = top.props.className ? top.props.className + " cursor-pointer " : " cursor-pointer ";

    const openStyles = open ? " transition-all ease-in block max-h-40 opacity-100 " : " transition-all ease-out block max-h-0 opacity-0 ";
    const defaultStyles = bottom.props.className ? bottom.props.className : " ";
    const bottomStyles = openStyles + defaultStyles;
    
    return (
        <Fragment>
            {React.cloneElement(top, {className: topStyles, onClick: () => setOpen(!open)})}
            {React.cloneElement(bottom, {className: bottomStyles})}
        </Fragment>
    )
}
