const projectList = [
    {
        name: `I developed a concept for a digital onboarding service for newly arriving migrants for the City Hall of Barcelona.`,
        year: `2020`,
        description: `The Service Center for Immigrants, Emigrants and Refugees (SAIER) is a one-stop service for migrants in Barcelona, run by the City Hall of Barcelona. I worked with them as part of my studies at ESADE, resulting in the design of SAIER InteliGente, a digital onboarding service for migrants that allows to digitally process information about their needs and identify emergency cases in due time. I talk about this project in detail here.
        `,
        type: `uni`,
    },
    {
        name: `I developed a financial model and investment plan for codesandbox.
        `,
        year: `2020`,
        description: `Codesandbox is a Dutch startup that provides a collaborative online code editor for individuals and organizations. With a team of ESADE students, I developed a financial model to project the company's future operations and liquidity under the premise of different product development and investment scenarios.`,
        type: `uni`,
    },
    {
        name: `I developed a content marketing strategy for Semalytix.
        `,
        year: `2020`,
        description: `Semalytix is a German healthcare technology startup that provides an AI-based research platform that aggregates patient voices to provide real world evidence data. I worked with them as part of my studies at ESADE and developed a content marketing strategy.
        `,
        type: `uni`,
    },
    {
        name: `I developed a concept for a special food service for elderly users for Glovo.
        `,
        year: `2019`,
        description: `Glovo is a Spanish startup that provides an on-demand courier service through a mobile app. I worked with them as part of a course at ESADE and developed the concept for a service specially catered towards elderly users.
        `,
        type: `uni`,
    },
    {
        name: `I developed a concept for a digital patient referral platform for Aalto Global Impact and Makerspace Nairobi.
        `,
        year: `2018`,
        description: `As part of my graduate studies at Aalto University, I developed a concept for a digital platform for the onboarding and referral of pregnant women between medical facilities in Kenya.
        `,
        type: `uni`,
    },
    {
        name: `I developed a concept for a social app at Junction Hackathon.
        `,
        year: `2018
        `,
        description: `I participated at Sitra's 'Mastering My Data' challenge for the 2018 Junction hackathon. My team developed a concept for a social app to meet people based on shared interest called 'We don't wanna hook up' and won a second runner-up prize among 30+ competitors.
        `,
        type: `uni`,
    },
    {
        name: `I did a consulting internship at Accenture Strategy.
        `,
        year: `2018`,
        description: `I supported the strategic realignment of a digital transformation program at an international insurance corporation and automated the reporting for the project portfolio. I also supported several digital lab initiatives and wrote a best practice guide on agile transformation.`,
        type: `intern`,
    },
    {
        name: `I developed a restaurant concept around healthy Vietnamese fast food with District Banh Mi.
        `,
        year: `2017
        `,
        description: `I joined some friends in developing a concept for a healthy Vietnamese fast food brand, working on different things from brand identity to store layout and product palette. I have continued to support them since launch to plan the expansion of the growing business.
        `,
        type: `freelance`,
    },
    {
        name: `I developed an investment memorandum for the sale of a technology firm with a private equity firm.
        `,
        year: `2017
        `,
        description: `I joined a private equity advisory firm in creating an investment memorandum for the sale of a +20 million EUR technology company. I conducted interviews with the company's department heads to map out the company's existing service portfolio and discussed viable M&A strategies with the CEO.
        `,
        type: `freelance`,
    },
    {
        name: `I conducted an augmented reality market and technology analysis for a mobile gaming company.
        `,
        year: `2016
        `,
        description: `I recruited and managed a team of analysts and engineers for an analysis of the augmented reality gaming market as well as for the review of an existing technology stack from a previously developed game. I also conducted a creative workshop in Mexico City.
        `,
        type: `freelance`,
    },
    {
        name: `I conducted an analysis of the Chinese market for a private technology investor.
        `,
        year: `2015
        `,
        description: `I recruited a team of Chinese professionals and conducted a detailed analysis of the Chinese market and its regulatory environment and steered a technology vendor selection process.
        `,
        type: `freelance`,
    },
    {
        name: `I conducted a market analysis for Orbis Investment Management.
        `,
        year: `2015
        `,
        description: `Orbis Investment Management is a global investment management firm based in Bermuda. I conducted an empirical market analysis of two major companies for one of Orbis' investment management teams.
        `,
        type: `freelance`,
    },
    {
        name: `I did an investment banking internship at Kuna & Co. (now Evercore).
        `,
        year: `2014
        `,
        description: `I worked as part of the Mergers & Acquisitions team on buy- and sellside mandates, primarily in the real estate industry. I also supported the team as a working student after the completion of my internship.`,
        type: `intern`,
    },
    {
        name: `I did a product management internship at Deutsche Bank's asset management division.
        `,
        year: `2014
        `,
        description: `Working with the firm's asset management division, I implemented a process to ensure the product portfolio's compliance with changing regulatory requirements, resulting in the prevention of high cost risks. I also developed a workflow-tool to reduce manual labor.
        `,
        type: `intern`,
    },
    {
        name: `I did an IT Management internship at Mercedes-Benz Financial Services China.
        `,
        year: `2013
        `,
        description: `I worked as a project manager on the introduction of asset backed securities to the Chinese market, which unlocked massive strategic revenue potential, driving multiple business and technology units towards a shared agenda. I also worked with the CIO to develop a business value driven IT strategy.
        `,
        type: `intern`,
    },
    {
        name: `I conducted an investment portfolio analysis for an international children's rights NGO.
        `,
        year: `2013
        `,
        description: `Working in a team of two, I analyzed the client's fixed return portfolio and assessed the attractivity of several asset classes to complement the existing portfolio. We also reviewd the compliance of existing investment positions with the organization's investment principles.
        `,
        type: `freelance`,
    },
    {
        name: `I conducted a market analysis for a management consulting firm.
        `,
        year: `2013
        `,
        description: `I researched several aspects about the business models of automotive financial service companies and derived an overview of the relevant players in the mobility services sector, such as leasing or carsharing companies.
        `,
        type: `freelance`,
    },
    {
        name: `I conducted an industry analysis for Debitos.
        `,
        year: `2013
        `,
        description: `Debitos is a financial technology company that provides a secondary marketplace for debt and real estate sales. I steered a team of three junior consultants in assessing the attractivity of a selection of industries in terms of the company's business model and identifying a set of target industries as recommendation for future marketing activities.
        `,
        type: `uni`,
    },
    {
        name: `I developed a business plan for a renewable energy NGO.
        `,
        year: `2012`,
        description: `With a team of three, I derived a business plan to present the NGO's model to potential supporters and developed an Excel-based tool for financial planning, including a financial model.
        `,
        type: `uni`,
    },
]

export default projectList;