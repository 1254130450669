import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectList from '../components/projectList'
import DropdownElement from "../components/dropdownElement"

const About = () => {

  const list = (data, color) => {
      return (
        <ul>
            {data.map((entry, index) => 
              <li key={entry.name} className={'relative mb-2'} style={{'zIndex': index}}>
                <DropdownElement 
                  top={<h5 className={'text-' + color + '-dark'} key={entry.name + "-top"}>{entry.name}</h5>} 
                  bottom={<small className={'text-' + color + '-dark mb-3 '} key={entry.name + "-bottom"}>{entry.year + " ∙ " + entry.description}</small>} 
                />
              </li>
            )}
        </ul>
      )
  }

  return (
    <Layout>
      <SEO title="About" />
        <h1 className='col-span-5 mt-12 col-start-1 md:col-start-2 md:col-span-3'>About my work</h1>
        <h5 className='col-span-5 mt-4 mb-2 col-start-1 leading-8 md:col-start-2 md:col-span-3'>I am a highly versatile and open-minded professional with fields of interests ranging from technology to design, arts, and literature. Through a broad variety of work experiences, I have developed a solid understanding of concept design, strategy, finance, and management as well as practical skills in graphic design and web development.</h5>
        <div className={'col-span-5 mt-4 mb-2 col-start-1 md:col-start-2 md:col-span-3 -mx-6 lg:-mx-12 px-6 lg:px-12 py-10 bg-red-lightest '}>
            <h3 className={'text-turquoise-dark mb-2'}>Internships</h3>
            {list(ProjectList.filter(el => el.type === "intern"), "turquoise")}
        </div> 
        <p className='col-span-5 mt-2 mb-4 col-start-1 leading-8 md:col-start-2 md:col-span-3'>I began my career with a Bachelor's degree in Philosophy &amp; Economics at Goethe-University in Frankfurt am Main. With Frankfurt being a major financial hub, I naturally found my way into the financial industry, through internships in investment banking and asset management. I also paused my studies for half a year to go to Beijing and work for Mercedes-Benz. Towards the end of my studies my interests turned to technology and design, which motivated me to do another internship at Accenture's strategy consulting division before embarking on graduate degrees at Aalto University (IDBM) and ESADE Business School (MIE), respectively.</p>
        <div className={'col-span-5 mt-4 mb-2 col-start-1 md:col-start-2 md:col-span-3 -mx-6 lg:-mx-12 px-6 lg:px-12 py-10 bg-yellow-lightest '}>
            <h3 className={'text-green-dark mb-2'}>Consulting Projects</h3>
            {list(ProjectList.filter(el => el.type === "freelance"), "green")}
        </div> 
        <p className='col-span-5 mt-2 mb-4 col-start-1 leading-8 md:col-start-2 md:col-span-3'>During my undergraduate studies I also started to work on freelance consulting projects, which allowed me to glimpse into a variety of industries and gain experience in negotiating project scopes and budgets as well as steering communication with executive leadership. Upon completing my Bachelor studies, I decided to leverage the network I had created and work on several projects with a technology background before continuing my education, which allowed me to gain first experience in properly multidisciplinary teams. I also used these projects to gain practical skills in graphic design and frontend development.</p>
        <div className={'col-span-5 mt-4 mb-2 col-start-1 md:col-start-2 md:col-span-3 -mx-6 lg:-mx-12 px-6 lg:px-12 py-10 bg-purple-lightest '}>
            <h3 className={'text-blue-dark mb-2'}>Other Projects</h3>
            {list(ProjectList.filter(el => el.type === "uni"), "blue")}
        </div> 
        <p className='col-span-5 mt-2 mb-4 col-start-1 leading-8 md:col-start-2 md:col-span-3'>I have also worked on a variety of projects as part of my graduate studies, student associations and hackathons, some of which were larger in scope and which I thus decided to list here. These are also good examples of my general interest to apply digital technologies to promote human flourishing and well-being. I have used these opportunities extensively to glimpse into different role profiles and their skillsets, complementing my analytic and strategic mindset with hands-on skills in product design and development. Consequently, I am fit to work in versatile configurations, filling whatever functions are required in the specific situation, or develop mature concepts individually, as a one-man prototyping army. During all this time, I held on to my philosophical roots, making me an inquisitive and thoughtful person in all endeavors I approach.</p>
      <Link to ="/" className="col-start-1 sm:col-start-2 col-span-5 sm_col-span-3 "><h5 className="underline mt-2">Back to main</h5></Link>
    </Layout>
  )
}

export default About
